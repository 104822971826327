import React from "react"
import View from "./View"
import { getCurrentUser } from "../utils/auth"

const Stories = () => {
  const { name, legalName, email } = getCurrentUser()

  return (
    <View title="Stories">
      <p>These are my stories.
      </p>
    </View>
  )
}

export default Stories
