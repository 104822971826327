import React from "react"
import View from "./View"
import { getCurrentUser, getProfile } from "../utils/auth"

const Thoughts = () => {
  const { name, legalName, email } = getCurrentUser()

  return (
    <View title="">
      <p>
        These are thoughts.
      </p>
    </View>
  )
}

export default Thoughts
