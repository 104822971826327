import React from "react"
import styles from "./resume.module.css"

export default () => (
  <div class="cv">
    <div class="mainDetails">
      <div class="headshot">
        <img src="wang_lei.jpg" alt="Wang Lei" />
      </div>
      
      <div class="name">
        <h1>Wang Lei</h1>
        <h2>Software Engineer</h2>
        <p>US Citizen</p>
      </div>
      
      <div class="contactDetails">
        <ul>
          <li>e: <a href="mailto:wangslei@gmail.com" target="_blank">wangslei@gmail.com</a></li>
          <li>w: <a href="https://wangslei.com">https://wangslei.com</a></li>
        </ul>
      </div>
      <div class="clear"></div>
    </div>
    
    <div class="mainArea">
      <section>
        <article>
          <div class="sectionTitle">
            <h1>Profile</h1>
          </div>
          
          <div class="sectionContent">
            <p>Picked up web development as a hobby back in 2013, hacking together the front end of an online meal sharing platform with a friend, and quickly realized that I really loved coding. I've worn many different hats since starting at Clover 4 years ago -- web engineer, full stack engineer, engineering manager, and most recently server infrastructure engineer.</p>
            <p>Currently looking for a good engineering team that provides the right balance of guidance and letting me figure things out on my own.</p>
          </div>
        </article>
        <div class="clear"></div>
      </section>

      <section>
        <div class="sectionTitle">
          <h1>Work Experience</h1>
        </div>

        <div class="sectionContent">
          <article>
            <h2>Clover Network</h2>
            <p class="subHeader">Server Infrastructure Engineer (March 2019 - Present)</p>
            <p class="subDetails">Tech: Java, Python (2.x), MySQL (Percona and Cloud SQL), Redis, Memcache, Docker, Kubernetes, Terraform, Puppet, Jenkins</p>
            <p>Build out and maintain server infrastructure used by product teams. On-call rotation for triaging infrastructure-related production issues and outages. Notable deliverables:</p>
            <p>
              <ul class="notable">
                <li>Backfilled one-to-one transaction to merchant join table -- ~6 billion rows across 20 shards -- to unblock the evacuation of our legacy transactional MySQL orders DB</li>
                <li>Enabled load testing in Clover's CI pipeline using Locust</li>
                <li>Part of the service decomposition effort to build out Clover’s new Authentication Service.</li>
              </ul>
            </p>

            <p class="subHeader">Engineering Manager (March 2017 - March 2019)</p>
            <p>Tech Lead and Manager for 3 product teams -- Customer Support and Internal Tools, App Market, Developer Platform. Hired, managed, and prioritized the workload for 13 engineers across the 3 teams.</p>

            <p class="subHeader">Web Engineer (December 2015 - March 2017)</p>
            <p class="subDetails">Tech: JavaScript, ES6, Ember, HTML5, Handlebars, CSS/Less/SCSS, nginx, Java</p>
            <p>Responsible for maintaining and enhancing Clover’s web and internal dashboard, which is an Ember single-page app built on 25 Ember engines. Engage with product manager and designer to create and implement new Clover's merchant dashboard features by maintaining and adding RESTful API endpoints. Reviewed code for other web engineers. Supported production rollout. Notable features:</p>
            <p>
              <ul class="notable">
                <li>Created extensible custom roles for Customer Support and Sales dashboard</li>
                <li>Enabled device-specific settings for merchants on the web</li>
                <li>Moved clover.com site assets to be served from Contentful CMS to allow marketing content to be modified dynamically outside of development cycle</li>
              </ul>
            </p>
          </article>

          <article>
            <h2>DropThought</h2>
            <p class="subHeader">Full Stack Engineer (December 2014 - November 2015)</p>
            <p class="subDetails">Tech: Redis, Node.js, Express, Angular.js</p>
            <p>Primary developer for DropThought’s Enterprise Dashboard product</p>
          </article>
        </div>
        <div class="clear"></div>
      </section>
      
      <section>
        <div class="sectionTitle">
          <h1>Technologies</h1>
        </div>
        
        <div class="sectionContent">
          <ul class="keySkills">
            <li>Java</li>Python (2.x)
            <li>Docker</li>
            <li>Kubernetes</li>
            <li>Jenkins</li>
            <li>MySQL</li>
            <li>Redis</li>
            <li>Memcache</li>
            <li>Locust</li>
            <li>Apache Avro</li>
            <li>JDBC</li>
            <li>Maven</li>
            <li>JavaScript</li>
            <li>ES6</li>
            <li>Ember</li>
            <li>React</li>
            <li>HTML5</li>
            <li>Handlebars</li>
            <li>CSS/Less/SCSS</li>
            <li>Git</li>
            <li>Phabricator</li>
            <li>Kibana</li>
            <li>Wavefront</li>
            <li>PagerDuty</li>
            <li>Jira</li>
            <li>Confluence</li>
          </ul>
        </div>
        <div class="clear"></div>
      </section>

      <section>
        <div class="sectionTitle">
          <h1>Education</h1>
        </div>
        
        <div class="sectionContent">
          <article>
            <h2>MIT</h2>
            <p class="subDetails">Bachelor of Science and Master of Engineering (2003-2008)</p>
            <p>Materials Science and Engineering</p>
          </article>
        </div>
        <div class="clear"></div>
      </section>
      
    </div>
  </div>
)
